import { ErrorResponse, onError } from '@apollo/client/link/error'

export default onError((errors: ErrorResponse) => {
  if (errors.graphQLErrors) {
    for (let i = 0; i < errors.graphQLErrors.length; i++) {
      const graphQLError = errors.graphQLErrors[i]
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const graphQLErrorCode = (graphQLError as any)?.code

      if (graphQLErrorCode === 'token_invalid') {
        window.location.href = '/logout'
        return
      }

      if (graphQLErrorCode === 'not_found') {
        window.location.href = '/404'
        return
      }
    }
  }
})

import { StoreSlice } from './useStore'

export type PageSizes = 12 | 20 | 30

export type PaginationProps = {
  page: number
  pageSize: PageSizes
}

export type PaginationType = {
  setPaginationProps: (props: PaginationProps) => void
} & PaginationProps

export const PAGE_SIZES: PageSizes[] = [12, 20, 30]

export const PAGINATION_DEFAULTS: PaginationProps = {
  page: 1,
  pageSize: 12,
}

export const createPaginationSlice: StoreSlice<PaginationType> = (set) => ({
  ...PAGINATION_DEFAULTS,
  setPaginationProps: (props: PaginationProps) => set(props),
})

import { PageSizes } from '@state/pagination'
import { ReactElement } from 'react'

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IPaginationVariables {
  page?: number
  pageSize?: PageSizes
}

export interface ISortVariables {
  sortBy?: string | number
  sortOrder?: SortOrder
}

export interface IPaginationAndSortVariables extends IPaginationVariables, ISortVariables {}

export interface IListProps<ListItems> {
  setOpenFiltersMobile?: (newValue: boolean) => void
  hasSearch?: boolean
  hasPagination?: boolean
  isLoading: boolean
  sortItems: {
    text: string
    value: string
  }[]
  noResultsItemName: string
  listItems: ListItems[]
  totalItems: number
  children(data: ListItems): ReactElement
}

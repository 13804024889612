// @ts-nocheck

export enum PAGES {
  PACKAGES = 'packages',
  CHECKOUT_SUCCESS_PAGE = 'checkout_success_page',
  BOOK_AN_EXPERT = 'book_an_expert_page',
}

export enum EVENTS {
  NAVIGATED = 'navigated',
  CHECKOUT_SUCCESS_LANDED = 'checkout_success_landed',
  NAVBAR_LOGO_CLICKED = 'navbar_logo_clicked',
  GET_STARTED_CLICKED = 'get_started_clicked',
  BUY_CLICKED = 'buy_clicked',
  EMAIL_INPUT_ENTERED = 'email_input_entered',
  IMAGE_CLICKED = 'image_clicked',
  BUY_REQUIREMENT_CHECKED = 'buy_requirement_checked',
  KEEP_EXPLORING = 'keep_exploring_clicked',
  FOOTER_PRIVACY_POLICY_CLICKED = 'sidebar_nav-item-footer_click',
  BOOK_EXPERT_CLICKED = 'book_expert_clicked',
}

export const NAVIGATED_EVENTS = [
  EVENTS.NAVBAR_LOGO_CLICKED,
  EVENTS.GET_STARTED_CLICKED,
  EVENTS.BUY_CLICKED,
  EVENTS.KEEP_EXPLORING,
]

const serializeData = (data) => btoa(JSON.stringify(data))
const deserializeData = (data) => JSON.parse(atob(data))

const tracking = {
  trackNavigated(props) {
    analytics.track(EVENTS.NAVIGATED, {
      ...props,
      origin_page_URL: window.location.href,
    })
  },
  async trackEvent(id, eventProperties = {}) {
    if (!window || !analytics) {
      return
    }

    analytics.track(id, eventProperties)

    if (NAVIGATED_EVENTS.includes(id)) {
      this.trackNavigated({
        destination_page_URL: eventProperties.destination_page_URL,
        page_name: eventProperties.page_name,
      })
    }
  },
  attachGlobalClickListener() {
    if (!window || !analytics) {
      return
    }

    const trackClickEvent = ({ target }) => {
      if (!target || target.tagName.toLowerCase() === 'body') {
        return null
      }

      if (!target.dataset?.trackingInfo) {
        /*
          Traverse the entire tree until we find an element with data-tracking-info or the <body> tag
          useful when we click an untracked element that is a child of an element that we wish to track.
          This might be an overkill, but since it's async I don't see a glaring performance impact atm
        */
        return trackClickEvent({ target: target.parentNode })
      }

      const { id, ...eventProperties } = deserializeData(target.dataset.trackingInfo)

      analytics.track(id, eventProperties)
      return null
    }

    if (document) {
      document.body.addEventListener('click', trackClickEvent)
    }
  },
}

export default tracking

export { serializeData }

import { IPackageFilter } from '@components/Packages/models'

import { StoreSlice } from './useStore'

export type FiltersType = {
  filters: Record<string, unknown>
  hasAppliedFilters: () => boolean
  fetchedFilters: IPackageFilter[]
  setFilters: (filters: Record<string, unknown>) => void
  setFetchedFilters: (filters: IPackageFilter[]) => void
}

export const createFiltersSlice: StoreSlice<FiltersType> = (set, get) => ({
  filters: {},
  hasAppliedFilters: () => !!Object.keys(get()?.filters ?? {}).length,
  fetchedFilters: [],
  setFilters: (filters: Record<string, unknown>) => {
    set({ filters })
  },
  setFetchedFilters: (filters: IPackageFilter[]) => {
    set({ fetchedFilters: filters })
  },
})

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { useMemo } from 'react'

import { marketplaceCache } from './apolloCache'
import apolloErrors from './apolloErrors'

let apolloClientSingleton: ApolloClient<InMemoryCache>

type Options = {
  headers: HeadersInit
}

function createApiLink() {
  const fetchWithAuthHeaders = (url: string, options: Options) => {
    const headers = {
      ...options.headers,
    }

    return fetch(url, {
      ...options,
      headers,
    }).then((response) => response)
  }
  return new HttpLink({
    uri: `https://${process.env.MARKETPLACE_HOST}/api/query`,
    credentials: 'include',
    fetch: fetchWithAuthHeaders,
  })
}

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    link: ApolloLink.from([apolloErrors, createApiLink()]),
    cache: marketplaceCache,
  })
}

export function initializeApollo(initialState: unknown) {
  const apolloClient = apolloClientSingleton ?? createApolloClient()
  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = apolloClient.extract()

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    apolloClient.cache.restore(Object.assign(existingCache, initialState))
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClientSingleton) apolloClientSingleton = apolloClient
  return apolloClient
}

export function useApollo(initialState: unknown) {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}

export type GeolocationContext = {
  id: string
  short_code: string
  text: string
}

export type GeolocationProperties = {
  short_code: string
}

export type GeolocationFeature = {
  geometry: {
    coordinates: [number, number]
  }
  context: GeolocationContext[]
  place_name: string
  place_type: string[]
  properties: GeolocationProperties
  id: string
  text: string
}

export interface IFetchGeolocation {
  features: GeolocationFeature[]
}

export const BASE_MAPBOX_URL = 'https://api.mapbox.com'
export const MAPBOX_ACCESS_TOKEN = process.env.MAPBOX_ACCESS_TOKEN

export const getGeolocationCoords = (searchText: string): Promise<IFetchGeolocation> => {
  return fetch(
    `${BASE_MAPBOX_URL}/geocoding/v5/mapbox.places/${searchText}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
  ).then((res) => res.json())
}

export const generateStaticMapUrl = (coords: [number, number], zoom = 9, size = '400x200') => {
  const [long, lat] = coords
  return `${BASE_MAPBOX_URL}/styles/v1/mapbox/streets-v11/static/${long},${lat},${zoom},0/${size}?access_token=${MAPBOX_ACCESS_TOKEN}`
}

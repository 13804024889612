import TagManager from 'react-gtm-module'

import { IUser } from '@models/user'

import { JourneyStepIds } from '@components/journey/models'

const GOOGLE_TAG_MANAGER_KEY = process.env.GOOGLE_TAG_MANAGER_KEY

enum GoogleTagEvents {
  MARKETPLACE_SIGNUP = 'marketplace-signup',
  CREATOR_FORM_SUBMIT = 'creator-form-submit',
  CREATOR_COMPLETED_FORM = 'creator-completed-form',
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const fireEvent = (event: GoogleTagEvents, data?: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      ...data,
    },
  })
}

const googleTagManager = {
  initialize() {
    if (GOOGLE_TAG_MANAGER_KEY) {
      const tagManagerArgs = {
        gtmId: GOOGLE_TAG_MANAGER_KEY,
      }

      TagManager.initialize(tagManagerArgs)
    }
  },
  onMarketplaceSignup(email: string, first: string, last: string, phone: string) {
    fireEvent(GoogleTagEvents.MARKETPLACE_SIGNUP, { user: { email, first, last, phone } })
  },
  onCreatorFormSubmit(
    user: IUser | undefined,
    stepId: JourneyStepIds,
    value: unknown,
    isCompleted: boolean
  ) {
    fireEvent(
      isCompleted ? GoogleTagEvents.CREATOR_COMPLETED_FORM : GoogleTagEvents.CREATOR_FORM_SUBMIT,
      {
        user: {
          email: user?.email,
          first: user?.firstName,
          last: user?.lastName,
          phone: user?.profile?.phone,
        },
        step: {
          name: stepId,
          value: value,
        },
      }
    )
  },
}

export default googleTagManager

import { createGlobalStyle } from 'styled-components'

import theme from './theme'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${theme.fonts.lato};
  }

  .modal-backdrop.show {
    background: rgba(247, 246, 245, 0.8);
    opacity: 0.9;
    backdrop-filter: blur(20px)
  }

  html,
  body,
  #__next {
    height: 100%;
  }
`

export default GlobalStyle

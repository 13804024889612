import { SortOrder } from '@components/layout/ListPage/List/models'

import { StoreSlice } from './useStore'

export type SortProps = {
  sortBy: string | number | null
  sortDirection: SortOrder
}

export type SortType = {
  setSortProps: (props: SortProps) => void
} & SortProps

export const SORT_DEFAULTS: SortProps = {
  sortBy: null,
  sortDirection: SortOrder.DESC,
}

export const createSortSlice: StoreSlice<SortType> = (set) => ({
  ...SORT_DEFAULTS,
  setSortProps: (props: SortProps) => set(props),
})

import { ApolloProvider } from '@apollo/client'
import '@fontsource/inter'
import '@fontsource/lato'
import '@fontsource/lato/100.css'
import '@fontsource/lato/300.css'
import '@fontsource/lato/700.css'
import '@fontsource/lato/900.css'
import '@fontsource/merriweather'
import '@fontsource/poppins'
import en from '@i18n/translations/en.json'
import { PAGINATION_DEFAULTS } from '@state/pagination'
import { SORT_DEFAULTS } from '@state/sort'
import { useStore } from '@state/useStore'
import GlobalStyle from '@theme/GlobalStyles'
import theme from '@theme/theme'
import 'bootstrap/dist/css/bootstrap.min.css'
// @ts-ignore
import mapboxgl from 'mapbox-gl'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useEffect, useState } from 'react'
import { SSRProvider } from 'react-bootstrap'
import { CookiesProvider } from 'react-cookie'
import { IntlProvider } from 'react-intl'
import 'react-lazy-load-image-component/src/effects/blur.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { ThemeProvider } from 'styled-components'

import { useApollo } from '@lib/apollo/apolloClient'
import googleTagManager from '@lib/gtm'
import { MAPBOX_ACCESS_TOKEN } from '@lib/mapbox'
import tracking from '@lib/tracking'

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN as string

const selector = (state) => ({
  setPaginationProps: state.setPaginationProps,
  setFilters: state.setFilters,
  setSort: state.setSortProps,
})

const processQueryFilters = (queryFilters: string) => {
  const filters = queryFilters.split(',')

  return Object.fromEntries(
    filters.map((filter) => {
      const [keyFilter, valueFilter] = filter.split('=')

      return [keyFilter, valueFilter ?? true]
    })
  )
}

const extractQueryParamsForLists = (queryParams: ParsedUrlQuery) => {
  //  the `filter` param is the legacy query param for group id
  const { filters, filter: packageGroupId, ...paginationParams } = queryParams
  const filtersParams = filters ? processQueryFilters(filters as string) : {}

  return {
    filtersParams: { ...filtersParams, packageGroupId },
    paginationParams,
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const router = useRouter()
  const { setPaginationProps, setFilters, setSort } = useStore(selector)
  const [isRouterReady, setIsRouterReady] = useState(false)

  useEffect(() => {
    googleTagManager.initialize()
    tracking.attachGlobalClickListener()
  }, [])

  useEffect(() => {
    const { filtersParams, paginationParams } = extractQueryParamsForLists(router.query)

    setPaginationProps({
      page: paginationParams.page
        ? parseInt(paginationParams.page as string)
        : PAGINATION_DEFAULTS.page,
      pageSize: paginationParams.pageSize
        ? parseInt(paginationParams.pageSize as string)
        : PAGINATION_DEFAULTS.pageSize,
    })

    setSort({
      sortBy: paginationParams.sortBy ?? SORT_DEFAULTS.sortBy,
      sortDirection: paginationParams.sortDirection ?? SORT_DEFAULTS.sortDirection,
    })

    setFilters(filtersParams ?? {})
    setIsRouterReady(router.isReady)
  }, [router.query, setIsRouterReady, setFilters, setPaginationProps])

  return (
    <ApolloProvider client={apolloClient}>
      <IntlProvider locale="en" messages={en} defaultLocale="en">
        <SSRProvider>
          <CookiesProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              {isRouterReady && <Component {...pageProps} />}
            </ThemeProvider>
          </CookiesProvider>
        </SSRProvider>
      </IntlProvider>
    </ApolloProvider>
  )
}

export default MyApp

import { createFiltersSlice, FiltersType } from '@state/filters'
import { createPaginationSlice, PaginationType } from '@state/pagination'
import { createSortSlice, SortType } from '@state/sort'
import { createToastsSlice, ToastsType } from '@state/toast'
import create, { GetState, SetState } from 'zustand'

export type SlicesState = PaginationType & FiltersType & SortType & ToastsType

export type StoreSlice<T> = (set: SetState<SlicesState>, get: GetState<SlicesState>) => T

export const useStore = create<SlicesState>((set, get) => ({
  ...createPaginationSlice(set, get),
  ...createFiltersSlice(set, get),
  ...createSortSlice(set, get),
  ...createToastsSlice(set, get),
}))

import { InMemoryCache } from '@apollo/client'

export const marketplaceCache = new InMemoryCache({
  typePolicies: {
    UserProfileType: {
      fields: {
        portfolios: {
          merge: false,
        },
      },
    },
  },
})

import { IToast } from '@models/toast'

import { StoreSlice } from './useStore'

export type ToastsType = {
  toasts: IToast[]
  setToast: (toast: IToast) => void
  setToasts: (toasts: IToast[]) => void
  getLatestToast: () => JSX.Element | string
}

export const createToastsSlice: StoreSlice<ToastsType> = (set, get) => ({
  toasts: [],
  getLatestToast: () => {
    const currentToasts = get().toasts
    return currentToasts.length > 0 ? currentToasts.slice(-1)[0].message : ''
  },
  setToast: (toast: IToast) => {
    set({
      toasts: [toast, ...get().toasts],
    })
  },
  setToasts: (toasts: IToast[]) => {
    set({
      toasts: [...toasts],
    })
  },
})

export default {
  black: '#000',
  dropShadow: '#2D6D901F',
  grayAtari: '#262222',
  grayGameboy: '#C6C1BD',
  grayNes: '#DEDBD9',
  grayWii: '#F7F6F5',
  grayDarker: '#EAE7E6',
  graySega: '#3d3838',
  grayNickelArcade: '#918d8a',
  grayPlaystation: '#615c5a',
  redCaliforniaRaisins: '#612a3d',
  redHungryHippo: '#f5a6b9',
  redJEM: '#d96483',
  redSurprise: '#FFF2F5',
  redPowerRangers: '#853852',
  redStrawberryShortcake: '#ba4967',
  redCandyLand: '#f7c6cf',
  purpleShopkins: '#3f3159',
  purpleMegatron: '#a08abf',
  purpleKoosh: '#f0ebf7',
  tealDollhouse: '#004359',
  tealGlove: '#00DBC9',
  tealLiteBrite: '#ecfafb',
  tealMonopoly: '#bee8e1',
  tealPogs: '#dcf7f3',
  tealPogsHigh: '#05dcca',
  tealPowerglove: '#73c2ba',
  tealRobots: '#3b9399',
  tealFurby: '#2a687b',
  greenTMNT: '#95ab5e',
  greenGumby: '#617224',
  greenBuzzLightyear: '#b4c97f',
  greenGloWorm: '#f1f5c6',
  greenCabbagePatch: '#414f28',
  greenMoonShoes: '#cfde9e',
  greenTinkerbell: '#e4edb7',
  purpleBeanieBaby: '#dcd1eb',
  purpleTrolls: '#5a476e',
  purpleBopit: '#7a6096',
  purplePollyPocket: '#bcabd1',
  goldenRod0: '#FFF5D9',
  goldenRod1000: '#B38300',
  white: '#FFF',
}
